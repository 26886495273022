
<template>
  <div v-if="activeUser" class="row account-page g-4">
    <div class="col-md-4 order-md-2">
      <div class="card position-sticky" style="top: 90px;">
        <img :src="absoluteUrl('/images/covers/profile-cover-2.jpg')" alt="" class="card-img-top">
        <div class="card-body text-center">
          <a href="javascript:void(0)" class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
            <img :src="absoluteUrl(activeUser.image)" class="avatar-img rounded-circle border border-4 border-card" alt="...">
          </a>
            <small>
              <span class="text-success">●</span> Online
            </small>
          <h3 class="card-title">
            <a href="javascript:void(0)">{{activeUser.first_name}} {{activeUser.last_name}}</a>
          </h3>
          <p class="small text-muted mb-3">
            Joined on {{$filters.date(activeUser.created_at)}}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row g-4">
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-tab-profile />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-tab-password />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserEditTabProfile     from "./UserEditTabProfile.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  name: "user-edit",
  components: {
    UserEditTabProfile,
    UserEditTabPassword,
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    },
  },
  created() {
    this.$store.dispatch("fetchActiveUser");
  },
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
</style>
